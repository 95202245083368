.App {
  text-align: center;
  text-decoration: none;
  scrollbar-color: "dark!important"
}

body{
  background-color:  #1c1c28!important;
   /* background-color:  #212121!important; */
  overflow-x: hidden;
  scrollbar-color: "dark!important"
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiFormLabel-root, .MuiInput-root, .MuiSelect-icon, .MuiCheckbox-root, .MuiPaper-root,
.MuiInput-underline, .MuiInput-underline::after{
  background-color: #292935!important;
   /* background-color: #303030!important; */
  color: #ededed!important;
}

pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.MuiSvgIcon-root, .MuiTouchRipple-root{
  color: #ededed!important;
} 

.MuiInput-underline:after{
  border-color: #ededed!important;
}